html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leveledContainer {
  height: 100vh;
  width: 100vw;
  position: relative;
  transform: rotate(45deg);
}

.leveled {
  height: 10000px;
  width: 10000px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #34C759;
}

.degrees {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  background: linear-gradient(to bottom, #34C759 50%, #fff 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.accessOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
}

.accessOverlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: white;
}

button {
  position: absolute;
  bottom: 20px;
  width: 180px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 40px;
  border: none;
  border-radius: 30px;
  background-color: rgba(57, 57, 57, 0.4);
  backdrop-filter: blur(10px);
  color: white;
}